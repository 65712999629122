import * as QueueActionTypes from '../config/queue_action_types';

// types of action
const Types = {
    LIST_ANNOUNCEMENTS_REQUEST: 'LIST_ANNOUNCEMENTS_REQUEST',
    LIST_ANNOUNCEMENTS_SUCCESS: 'LIST_ANNOUNCEMENTS_SUCCESS',
    READ_ANNOUNCEMENT_REQUEST: 'READ_ANNOUNCEMENT_REQUEST',
    READ_ANNOUNCEMENT_SUCCESS: 'READ_ANNOUNCEMENT_SUCCESS',
    DISMISS_ANNOUNCEMENT_REQUEST: 'DISMISS_ANNOUNCEMENT_REQUEST',
    DISMISS_ANNOUNCEMENT_SUCCESS: 'DISMISS_ANNOUNCEMENT_SUCCESS',
};

// actions
const listAnnouncements = () => ({
    type: Types.LIST_ANNOUNCEMENTS_REQUEST,
        meta: {
        offline: {
            effect: { url: Routes.announcement_list_path({ all: true, active: true}) },
            commit: { type: Types.LIST_ANNOUNCEMENTS_SUCCESS },
            queue: { method: QueueActionTypes.READ, key: 'list', scope: 'announcement_read' }
        }
    }
});

const readAnnouncement = (token, announcementId) => ({
    type: Types.READ_ANNOUNCEMENT_REQUEST,
    meta: {
        offline: {
            effect: { url: Routes.announcement_read_path({ announcement_id: announcementId }), method: 'POST', body: JSON.stringify({ authenticity_token: token }) },
            commit: { type: Types.READ_ANNOUNCEMENT_SUCCESS, meta: { announcementId } },
            queue: { method: QueueActionTypes.READ, key: announcementId, scope: 'announcement_read' }
        }
    }
});

const dismissAnnouncement = (token, announcementId) => ({
    type: Types.DISMISS_ANNOUNCEMENT_REQUEST,
    meta: {
        offline: {
            effect: { url: Routes.announcement_dismiss_path({ announcement_id: announcementId }), method: 'POST', body: JSON.stringify({ authenticity_token: token }) },
            commit: { type: Types.DISMISS_ANNOUNCEMENT_SUCCESS, meta: { announcementId } },
            queue: { method: QueueActionTypes.READ, key: announcementId, scope: 'announcement_dismiss' }
        }
    }
});

export default {
    Types,
    listAnnouncements,
    readAnnouncement,
    dismissAnnouncement
};